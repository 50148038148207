/* Works - pass "my-custom-class" in cssClass to increase specificity */
ion-modal.confirm-modal-class {
  --background: white !important;

  //--height: 380px !important;
  --width: min(90%, 460px) !important;
  --box-shadow: 0 !important;
  --border-radius: 0.5rem !important;
  --backdrop-opacity: 0.5 !important;
  --height: fit-content !important;
}

ion-modal.full-page-modal {
  --background: white !important;
  --height: 100% !important;
  --width: 100% !important;
  --box-shadow: 0 !important;
  --backdrop-opacity: 0.5 !important;
}

ion-modal.search-parent-class {
  @include media-breakpoint('gt-xs') {
    --width: min(90%, 700px) !important;
    --height: min(90vh, 780px) !important;
    --border-radius: 0.5em;
  }
}

ion-modal.address-picker {
  // @include media-breakpoint('gt-xs') {
  //   --height: 500px;
  //   --width: 500px;
  //   --border-radius: .5rem !important;
  //   // position: relative !important;
  // }
}

.mat-mdc-standard-chip:focus::after {
  opacity: 0 !important;
}

ion-modal.settings-list-edit-modal {
  // resize: both !important;

  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 600px);
    --width: min(80%, 550px);
    --border-radius: 0.5rem !important;

    // position: relative !important;
  }
}

ion-modal.self-update-email {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 290px);
    --width: min(80%, 550px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.self-update-profile {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 370px);
    --width: min(80%, 550px);
    --border-radius: 0.5rem !important;

    // position: relative !important;
  }
}

ion-modal.create-update-user {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 555px);
    --width: min(80%, 600px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.create-document-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 820px);
    --width: min(80%, 1248px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.monetization-modal {
  @include media-breakpoint('gt-xs') {
    --min-width: min(80%, 1200px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.view-stock-modal {
  @include media-breakpoint('gt-xs') {
    --min-width: min(80%, 1600px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.add-waybill-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 800px);
    --width: min(80%, 1200px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.add-license-plate {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 360px);
    --width: min(80%, 650px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.change-password-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 370px);
    --width: min(80%, 650px);
    --border-radius: 0.5rem !important;
  }
}

.icon-picker-popover .popover-content {
  --border-radius: 0.5rem !important;
  --height: 330px !important;
  --width: 460px !important;
}

ion-modal.view-delivery-transports {
  --width: 90% !important;
  --border-radius: 0.5rem !important;
}

ion-modal.auto-height {
  --height: 100%;

  @include media-breakpoint('gt-xs') {
    --max-width: 80%;
    --height: auto;
    --border-radius: 0.5rem;
  }

  .ion-page {
    position: relative;
    contain: content;
    display: block;
  }

  .inner-content {
    overflow-y: scroll;
    height: calc(100% - 56px);
    padding: 1rem;
  }
}

ion-modal.auto-height {
  --height: 100%;

  @include media-breakpoint('gt-xs') {
    --width: min(80%, 650px);
    --height: auto;
    --border-radius: 0.5rem !important;

    .ion-page {
      position: relative;
      contain: content;
      display: block;
    }

    .inner-content {
      overflow: auto;
      padding: 1rem;
    }
  }

  .ion-page {
    position: relative;
    contain: content;
    display: block;
  }

  .inner-content {
    overflow: auto;
    max-height: 80vh;
    padding: 1rem;
  }

  ion-footer {
    max-width: 100% !important;
    padding: 0 1rem !important;
  }
}

ion-modal.safe-takeout-modal {
  @include media-breakpoint('gt-xs') {
    //--height: min(90vh, 360px);
    --width: min(90%, 1200px) !important;
    --border-radius: 0.5rem !important;
  }
}

ion-modal.close-inner-box-modal {
  @include media-breakpoint('gt-xs') {
    //--height: min(90vh, 360px);
    --width: min(90%, 1600px) !important;
    --border-radius: 0.5rem !important;
  }
}

ion-modal.change-pricing-rule {
  @include media-breakpoint('gt-xs') {
    //--height: min(90vh, 360px);
    --width: min(90%, 850px) !important;
    --border-radius: 0.5rem !important;
  }
}

ion-modal.check-previous-item-prices-modal {
  @include media-breakpoint('gt-xs') {
    //--height: min(90vh, 360px);
    --width: min(90%, 1600px) !important;
    --border-radius: 0.5rem !important;
  }
}

ion-modal.set-missing-payment-rule {
  @include media-breakpoint('gt-xs') {
    //--height: min(90vh, 360px);
    --width: min(90%, 1200px) !important;
    --border-radius: 0.5rem !important;
  }
}

ion-modal.backdrop-opacity {
  --backdrop-opacity: 0.5 !important;
}
