//ion-button {
//  text-transform: none !important;
//
//  button {
//    padding: 1.5em 2em !important;
//    border-radius: 10px !important;
//  }
//}

.icon-button {
  text-transform: none !important;

  button {
    padding: 8px !important;
    border-radius: 40px !important;
  }
}

ion-button {
  height: 50px;

  a {
    // --

    font-size: 16px !important;
    font-weight: 700 !important;
    font-style: normal !important;
    line-height: 19px !important;
    text-decoration: none !important;
    text-transform: none !important;
    letter-spacing: 0 !important;

    border-radius: 6px;
  }
}

ion-button::part(native) {
  padding: 1.5em 2em !important;

  // --

  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  line-height: 19px;
  text-align: left;
  text-transform: none !important;
  letter-spacing: 0.7px;

  border-radius: 6px;
}

button:not(.mat-mdc-icon-button, .button-native.sc-ion-fab-button-md) {
  text-transform: none !important;
  border-radius: 6px;
}
