//@import 'tailwindcss/base';
//@import 'tailwindcss/components';
//@import 'tailwindcss/utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import 'src/app/shared/scss/core';
@import 'src/app/shared/scss/ionic-override';
@import 'src/app/shared/scss/modal-wrappers';
@import 'theme/m3/theme.css';

//::-webkit-scrollbar, *::-webkit-scrollbar {
//  display: none;
//  overflow: hidden;
//}
//
//ion-content {
//  overflow: hidden;
//  --overflow: hidden;
//}
//
//.scroll-content {
//  overflow: hidden;
//}

// --

:root {
  --ion-safe-area-top: 0 !important;

  // --ion-safe-area-bottom: 22px;
}

//
//::ng-deep {
//  --ion-safe-area-top: 100px !important;
//}
//
//.ios {
//  --ion-safe-area-top: 100px !important;
//}

ion-app {
  margin-top: env(safe-area-inset-top);
}

.mat-mdc-text-field-wrapper {
  background: #f6f8fb !important;
  border-radius: 8px;
}

.white-form {
  .mat-mdc-text-field-wrapper {
    background: white !important;
  }
}

.blue-form {
  .mat-mdc-text-field-wrapper {
    background: var(--main-blue-90) !important;
  }

  &.bold-label {
    .mat-mdc-text-field-wrapper
      .mat-mdc-form-field-flex
      .mat-mdc-floating-label {
      font-size: 18px;
      font-weight: 600;
      color: var(--main-grey-05) !important;
    }
  }
}

.red-form {
  .mat-mdc-text-field-wrapper {
    background: var(--main-error-90) !important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    font-weight: 600;
    color: var(--main-error-40) !important;
  }
}

input:-webkit-autofill {
  box-shadow: 0 0 0 50px #fafbfd inset; /* Change the color to your own background color */

  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  box-shadow: 0 0 0 50px #fafbfd inset; /* your box-shadow */

  -webkit-text-fill-color: #333;
}

body {
  display: grid !important;

  width: 100% !important;
  min-width: 350px !important;
  height: 100% !important;

  font-family: Roboto, sans-serif !important;
  font-size: 16px;
  color: black;

  background: white !important;
}

*:not(mat-icon, ion-icon, i) {
  font-family: Inter, sans-serif !important;
}

.header-margin {
  display: block;
  margin-bottom: 1em !important;
}

.no-padding-dialog .mat-mdc-dialog-container {
  max-height: 60vh;
  padding: 0 !important;
}

.br-10 {
  border-radius: 10px;
}

.right-label {
  width: fit-content;
  text-align: right;
  text-overflow: unset;
  border: 0 !important;

  .item-inner {
    border: 0 !important;
  }
}

.mb-1em {
  margin-bottom: 1em !important;
}

.spacer {
  flex: 1 1 auto;
}

//@media (prefers-color-scheme: dark) {
//  :host ::ng-deep {
//    ion-item {
//      --background: transparent;
//    }
//  }
//}

//.mat-checkbox-layout .mat-checkbox-label {
//  font-family: "Inter", sans-serif !important;
//}

.custom-snackbar {
  max-width: 700px !important;
  min-height: 0 !important;
  padding: 0 !important;

  background: transparent;
  box-shadow: none;
}

.document-snackbar {
  width: 600px !important;
  max-width: 600px !important;
  min-height: 0 !important;
  padding: 0 !important;

  background: transparent;
  box-shadow: none;
}

.m0auto {
  margin: 0 auto;
}

#background-content {
  background-color: transparent !important;
}

:host ::ng-deep {
  .mat-mdc-icon-button {
    border-radius: 50% !important;
  }

  .mat-mdc-button,
  .mat-mdc-icon-button,
  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button {
    border-radius: 50% !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 0 !important;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px grey !important;
  border-radius: 0 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: none !important;
  border-radius: 0 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: none !important;
}

.w-100 {
  width: 100% !important;
}

///* width */
//.inner-scroll ::-webkit-scrollbar {
//  width: 0 !important;
//}
//
///* Track */
//.inner-scroll ::-webkit-scrollbar-track {
//  // box-shadow: inset 0 0 5px grey !important;
//  border-radius: 0 !important;
//}
//
///* Handle */
//.inner-scroll ::-webkit-scrollbar-thumb {
//  background: none !important;
//  border-radius: 0 !important;
//}
//
///* Handle on hover */
//.inner-scroll ::-webkit-scrollbar-thumb:hover {
//  background: none !important;
//}
//
//
//
///* width */
//.inner-scroll ::-webkit-scrollbar {
//  width: 0 !important;
//}
//
///* Track */
//.inner-scroll ::-webkit-scrollbar-track {
//  // box-shadow: inset 0 0 5px grey !important;
//  border-radius: 0 !important;
//}
//
///* Handle */
//.inner-scroll ::-webkit-scrollbar-thumb {
//  background: none !important;
//  border-radius: 0 !important;
//}
//
///* Handle on hover */
//.inner-scroll ::-webkit-scrollbar-thumb:hover {
//  background: none !important;
//}

.light-button {
  box-sizing: border-box !important;
  color: #2f5486;
  border: 1px solid #bdcad9 !important;
  border-radius: 4.52229px;
}

.icon-button-24-16 {
  display: grid;
  align-items: center;

  .mat-icon {
    width: 24px !important;
    height: 24px !important;
    font-size: 24px !important;
    line-height: 24px !important;
  }

  @media screen and (width <= 1599px) {
    .mat-icon {
      width: 16px !important;
      height: 16px !important;
      font-size: 16px !important;
      line-height: 16px !important;
    }
  }
}

.purple {
  color: var(--ion-color-tertiary);
}

.success-font {
  color: var(--ion-color-success);
}

.purple-bg {
  color: white;
  background-color: #7068b4;
}

//.mat-checkbox-frame {
//  border-radius: 1rem !important;
//  //padding: 8px !important;
//}
//
//.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
//  background: var(--ion-color-tertiary);
//  margin: 3px !important;
//  width: 10px;
//  height: 10px;
//  border-radius: 1rem;
//}
//
//.mat-checkbox-checked .mat-checkbox-checkmark {
//  opacity: 0 !important;
//}

ion-header {
  ion-toolbar {
    ion-title {
      color: #1a202c;
    }
  }
}

.highcharts-credits {
  display: none !important;
  opacity: 0 !important;
}

.hyp {
  hyphens: auto !important;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}

.full-loader-parent {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}

.header-chip {
  pointer-events: none;

  display: grid !important;
  align-content: center;
  justify-content: center;

  width: 32px;
}

.ngx-file-drop__drop-zone {
  box-sizing: border-box !important;
  height: fit-content !important;

  color: #696f79 !important;

  background: rgb(173 186 207 / 10%);
  border: 1px dashed #2f5486;
  border-radius: 6px !important;

  * {
    text-align: center !important;
  }
}

.ngx-file-drop__content {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  height: fit-content !important;
  min-height: 114px;
  padding: 20px !important;

  color: #696f79 !important;
}

.ngx-file-drop__drop-zone--over {
  background: #dcdce3 !important;
  border: 1px solid #a0abbb !important;
}

.add-icon-btn {
  color: #7068b4;
  background-color: rgb(112 104 180 / 15%);
}

.icon-size28 {
  display: flex !important;
  align-items: center;
  justify-content: center;

  width: 28px !important;
  height: 28px !important;
}

.demoDummy {
  position: absolute;
  top: 200px;
  left: 200px;

  font-size: 9rem;
  color: red;

  background: black;
}

.placeholder-text {
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  color: var(--main-grey-70);
  text-align: center;
}

.mat-mdc-menu-content:not(:empty) {
  color: #676c74 !important;
  background: #f7f9fb;
  border: 1px solid rgb(47 84 134 / 10%);
  border-radius: 6px;

  mat-icon,
  span {
    color: #676c74 !important;
  }

  span {
    font-weight: 500 !important;
  }
}

//ion-content.page main {
//  transition: all 0.5s ease !important;
//}
//:host ::ng-deep main.inner-scroll.scroll-y {
//  transition: all 0.5s ease !important;
//}
//main {
//  transition: all 0.5s ease !important;
//}
//
//ion-content::part(padding) {
//  transition: padding 2s !important;
//}

.null-padding-content {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --padding-top: 0 !important;
}

.no-pad {
  .mat-mdc-text-field-wrapper {
    padding-bottom: 0 !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

/* Works - pass "my-custom-class" in cssClass to increase specificity */
.my-custom-action-sheet-class .action-sheet-group {
  //background: red;
  //--border-radius: 12px !important;
}

.h-fit-content {
  height: fit-content !important;
}

ion-back-button {
  color: var(--ion-color-primary);
  background: var(--primary-10);
  border-radius: 50%;
}

.bg-blue-100 {
  background-color: var(--main-blue-100);
}

// Removing the arrows from number inputs

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
}

.suffixes-initial {
  .mat-form-field-prefix {
    position: sticky !important;
  }

  .mat-form-field-suffix {
    position: sticky !important;
  }
}

app-promise-button {
  width: fit-content;
}

.currency-suffix {
  position: absolute;
  right: 8px;
  bottom: 18px;

  font-size: 0.875rem;
  font-weight: 600;
  line-height: normal;
  color: var(--main-grey-30);
}

.mat-datepicker-content .mat-calendar {
  height: fit-content !important;
}

.primary-font {
  color: var(--ion-color-primary) !important;
}

.primary-bg {
  color: white !important;
  background: var(--ion-color-primary) !important;
}

.filled-button {
  height: fit-content !important;
  font-weight: 700 !important;
  color: var(--main-red-50) !important;
  background: var(--main-red-90) !important;
}

// No random line in mat-form-field outlined
// -----------------------------------------
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-right: none !important;
}

// -----------------------------------------

// region M3 Bottom Sheet
// -------------------------------------

.mat-menu-panel.status-menu {
  max-width: 375px !important;
  background-color: white;
}

.action-sheet-wrapper.sc-ion-action-sheet-md {
  right: 0.5rem !important;
  bottom: 0.5rem !important;
  left: 0.5rem !important;
  width: auto !important;
}

.action-sheet-group {
  border-radius: 0.5rem 0.5rem 0 0 !important;
}

.action-sheet-group.action-sheet-group-cancel {
  border-radius: 0 0 0.5rem 0.5rem !important;
}

// -------------------------------------
// endregion M3 Bottom Sheet

// SnackBar FIX
.mat-mdc-snack-bar-container .mdc-snackbar__surface,
.mdc-snackbar__surface,
.mdc-snackbar__label {
  padding: 0 !important;
  background: none !important;
  box-shadow: none !important;
}

.mat-icon {
  //display: inherit !important;
}

.grey-50 {
  color: var(--main-grey-50);
}

.grey-30 {
  color: var(--main-grey-30);
}

.grey-10 {
  color: var(--main-grey-10) !important;
}

.grey-5 {
  color: var(--main-grey-5) !important;
}

mat-select-country {
  mat-form-field {
    width: 100% !important;
  }
}

.icon-btn-small {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  font-size: 18px !important;

  .mat-mdc-button-touch-target {
    width: fit-content !important;
    height: fit-content !important;
  }
}

.lg-container {
  max-width: 1200px;
  margin-inline: auto;
}

.mat-mdc-outlined-button:not(:disabled) {
  border: 1px solid var(--main-red-40) !important;
}

.mat-mdc-text-field-wrapper {
  border-radius: 10px !important;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-line-ripple::before {
  border-bottom: none !important;
}

.placeholder-title {
  font-size: 24px;
  font-weight: 700;
  color: var(--main-grey-20);
}

.no-pseudo-select {
  .mat-pseudo-checkbox {
    display: none;
  }
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
