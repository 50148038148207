@use '@angular/material' as mat;

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '@angular/material/theming';
@import './constants';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies;
@include mat.core;

// Typography
$custom-typography: mat.define-typography-config(
  $font-family: Raleway,
  $headline-5: mat.define-typography-level(24px, 48px, 400),
  $body-2: mat.define-typography-level(16px, 24px, 400),
);

@include mat.all-component-typographies($custom-typography);

//:root {
//  --primary-10: rgba(47, 84, 134, 0.1);
//  --primary-20: rgba(47, 84, 134, 0.2);
//
//  --success-10: rgba(45, 211, 111, 0.1);
//  --success-20: rgba(45, 211, 111, 0.2);
//
//  --warn-10: rgba(255, 196, 9, 0.1);
//  --warn-20: rgba(255, 196, 9, 0.2);
//
//  --danger-10: rgba(235, 68, 90, 0.1);
//  --danger-20: rgba(235, 68, 90, 0.2);
//}

$light-primary-text: white;
$dorothy-primary-palette: (
  50: rgb(228 39 73 / 100%),
  100: rgb(228 39 73 / 100%),
  200: rgb(228 39 73 / 100%),
  300: rgb(228 39 73 / 100%),
  400: rgb(228 39 73 / 100%),
  500: rgb(228 39 73 / 100%),
  600: rgb(228 39 73 / 100%),
  700: rgb(228 39 73 / 100%),
  800: rgb(228 39 73 / 100%),
  900: rgb(228 39 73 / 100%),
  a100: rgb(228 39 73 / 100%),
  a200: rgb(228 39 73 / 100%),
  a400: rgb(228 39 73 / 100%),
  a700: rgb(228 39 73 / 100%),
  contrast: (
    50: $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    a100: $light-primary-text,
    a200: $light-primary-text,
    a400: $light-primary-text,
    a700: $light-primary-text,
  ),
);

// Default colors
//$my-app-primary: mat.define-palette(mat.$teal-palette, 700, 100, 800);
//$my-app-accent: mat.define-palette(mat.$teal-palette, 700, 100, 800);

// Define the primary, accent and warn palettes
$dorothy-primary: mat.define-palette($dorothy-primary-palette, 200, 500, 300);
$dorothy-accent: mat.define-palette($dorothy-primary-palette, 200, 500, 300);
$dorothy-warn: mat.define-palette(mat.$deep-orange-palette, 500, 200, 700);

//$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent);
$dorothy-theme: mat.define-light-theme(
  $dorothy-primary,
  $dorothy-accent,
  $dorothy-warn
);

//@include mat.all-component-themes($my-app-theme);
@include mat.all-component-themes($dorothy-theme);

// Dark theme
//$dark-primary: mat.define-palette(mat.$blue-grey-palette);
//$dark-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
//$dark-warn: mat.define-palette(mat.$deep-orange-palette);
//
//$dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);
//
//.dark-theme {
//  @include mat.all-component-themes($dark-theme);
//}

// Light theme
//$light-primary: mat.define-palette(mat.$grey-palette, 200, 500, 300);
//$light-accent: mat.define-palette(mat.$brown-palette, 200);
//$light-warn: mat.define-palette(mat.$deep-orange-palette, 200);
//
//$light-theme: mat.define-light-theme(
//  $light-primary,
//  $light-accent,
//  $light-warn
//);
//
//.light-theme {
//  @include mat.all-component-themes($light-theme);
//}

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: rgb(190 000 052 / 100%);
  --ion-color-primary-rgb: 0, 0, 0;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #000;
  --ion-color-primary-tint: #1a1a1a;
  --ion-color-secondary: #e5af55;
  --ion-color-secondary-rgb: 229, 175, 85;
  --ion-color-secondary-contrast: #000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #ca9a4b;
  --ion-color-secondary-tint: #e8b766;
  --ion-color-tertiary: #7068b4;
  --ion-color-tertiary-rgb: 112, 104, 180;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #635c9e;
  --ion-color-tertiary-tint: #7e77bc;
  --ion-color-success: #179884;
  --ion-color-success-rgb: 23, 152, 132;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #148674;
  --ion-color-success-tint: #2ea290;
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-danger: #ec524b;
  --ion-color-danger-rgb: 236, 82, 75;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d04842;
  --ion-color-danger-tint: #ee635d;
  --ion-color-dark: #383733;
  --ion-color-dark-rgb: 56, 55, 51;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #31302d;
  --ion-color-dark-tint: #4c4b47;
  --ion-color-medium: #969389;
  --ion-color-medium-rgb: 150, 147, 137;
  --ion-color-medium-contrast: #000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #848179;
  --ion-color-medium-tint: #a19e95;
  --ion-color-light: #faf5e4;
  --ion-color-light-rgb: 250, 245, 228;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dcd8c9;
  --ion-color-light-tint: #fbf6e7;
}

//@media (prefers-color-scheme: dark) {
//  /*
//   * Dark Colors
//   * -------------------------------------------
//   */
//
//  body {
//    --ion-color-primary: #35973a;
//    --ion-color-primary-rgb: 53,151,58;
//    --ion-color-primary-contrast: #ffffff;
//    --ion-color-primary-contrast-rgb: 255,255,255;
//    --ion-color-primary-shade: #2f8533;
//    --ion-color-primary-tint: #49a14e;
//
//    --ion-color-secondary: #665dea;
//    --ion-color-secondary-rgb: 102,93,234;
//    --ion-color-secondary-contrast: #ffffff;
//    --ion-color-secondary-contrast-rgb: 255,255,255;
//    --ion-color-secondary-shade: #5a52ce;
//    --ion-color-secondary-tint: #756dec;
//
//    --ion-color-tertiary: #eec83f;
//    --ion-color-tertiary-rgb: 238,200,63;
//    --ion-color-tertiary-contrast: #000000;
//    --ion-color-tertiary-contrast-rgb: 0,0,0;
//    --ion-color-tertiary-shade: #d1b037;
//    --ion-color-tertiary-tint: #f0ce52;
//
//    --ion-color-success: #2dd36f;
//    --ion-color-success-rgb: 45,211,111;
//    --ion-color-success-contrast: #ffffff;
//    --ion-color-success-contrast-rgb: 255,255,255;
//    --ion-color-success-shade: #28ba62;
//    --ion-color-success-tint: #42d77d;
//
//    --ion-color-warning: #ffc409;
//    --ion-color-warning-rgb: 255,196,9;
//    --ion-color-warning-contrast: #000000;
//    --ion-color-warning-contrast-rgb: 0,0,0;
//    --ion-color-warning-shade: #e0ac08;
//    --ion-color-warning-tint: #ffca22;
//
//    --ion-color-danger: #eb445a;
//    --ion-color-danger-rgb: 235,68,90;
//    --ion-color-danger-contrast: #ffffff;
//    --ion-color-danger-contrast-rgb: 255,255,255;
//    --ion-color-danger-shade: #cf3c4f;
//    --ion-color-danger-tint: #ed576b;
//
//    --ion-color-dark: #222428;
//    --ion-color-dark-rgb: 34,36,40;
//    --ion-color-dark-contrast: #ffffff;
//    --ion-color-dark-contrast-rgb: 255,255,255;
//    --ion-color-dark-shade: #1e2023;
//    --ion-color-dark-tint: #383a3e;
//
//    --ion-color-medium: #92949c;
//    --ion-color-medium-rgb: 146,148,156;
//    --ion-color-medium-contrast: #ffffff;
//    --ion-color-medium-contrast-rgb: 255,255,255;
//    --ion-color-medium-shade: #808289;
//    --ion-color-medium-tint: #9d9fa6;
//
//    --ion-color-light: #f4f5f8;
//    --ion-color-light-rgb: 244,245,248;
//    --ion-color-light-contrast: #000000;
//    --ion-color-light-contrast-rgb: 0,0,0;
//    --ion-color-light-shade: #d7d8da;
//    --ion-color-light-tint: #f5f6f9;
//  }
//
//  /*
//   * iOS Dark Theme
//   * -------------------------------------------
//   */
//
//  .ios body {
//    --ion-background-color: #000000;
//    --ion-background-color-rgb: 0, 0, 0;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255, 255, 255;
//
//    --ion-color-step-50: #0d0d0d;
//    --ion-color-step-100: #1a1a1a;
//    --ion-color-step-150: #262626;
//    --ion-color-step-200: #333333;
//    --ion-color-step-250: #404040;
//    --ion-color-step-300: #4d4d4d;
//    --ion-color-step-350: #595959;
//    --ion-color-step-400: #666666;
//    --ion-color-step-450: #737373;
//    --ion-color-step-500: #808080;
//    --ion-color-step-550: #8c8c8c;
//    --ion-color-step-600: #999999;
//    --ion-color-step-650: #a6a6a6;
//    --ion-color-step-700: #b3b3b3;
//    --ion-color-step-750: #bfbfbf;
//    --ion-color-step-800: #cccccc;
//    --ion-color-step-850: #d9d9d9;
//    --ion-color-step-900: #e6e6e6;
//    --ion-color-step-950: #f2f2f2;
//
//    --ion-item-background: #000000;
//
//    --ion-card-background: #1c1c1d;
//  }
//
//  .ios ion-modal {
//    --ion-background-color: var(--ion-color-step-100);
//    --ion-toolbar-background: var(--ion-color-step-150);
//    --ion-toolbar-border-color: var(--ion-color-step-250);
//  }
//
//
//  /*
//   * Material Design Dark Theme
//   * -------------------------------------------
//   */
//
//  .md body {
//    --ion-background-color: #121212;
//    --ion-background-color-rgb: 18, 18, 18;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255, 255, 255;
//
//    --ion-border-color: #222222;
//
//    --ion-color-step-50: #1e1e1e;
//    --ion-color-step-100: #2a2a2a;
//    --ion-color-step-150: #363636;
//    --ion-color-step-200: #414141;
//    --ion-color-step-250: #4d4d4d;
//    --ion-color-step-300: #595959;
//    --ion-color-step-350: #656565;
//    --ion-color-step-400: #717171;
//    --ion-color-step-450: #7d7d7d;
//    --ion-color-step-500: #898989;
//    --ion-color-step-550: #949494;
//    --ion-color-step-600: #a0a0a0;
//    --ion-color-step-650: #acacac;
//    --ion-color-step-700: #b8b8b8;
//    --ion-color-step-750: #c4c4c4;
//    --ion-color-step-800: #d0d0d0;
//    --ion-color-step-850: #dbdbdb;
//    --ion-color-step-900: #e7e7e7;
//    --ion-color-step-950: #f3f3f3;
//
//    --ion-item-background: #1e1e1e;
//
//    --ion-toolbar-background: #1f1f1f;
//
//    --ion-tab-bar-background: #1f1f1f;
//
//    --ion-card-background: #1e1e1e;
//  }
//}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
