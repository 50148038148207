table {
  border-spacing: 0 1rem !important;
  border-collapse: separate !important;
}

th.mat-mdc-header-cell {
  padding: 0 1rem !important;
  border: none !important;
}

.my-row {
  background: white !important;

  td.mat-mdc-cell {
    padding: 1rem !important;
    border-bottom: none !important;

    //border: none !important;

    &:first-of-type {
      border-radius: 0.5rem 0 0 0.5rem !important;
    }

    &:last-of-type {
      border-radius: 0 0.5rem 0.5rem 0 !important;
    }
  }
}

.small-padding-row {
  background: white !important;

  th.mat-mdc-header-cell {
    padding: 0 0.5rem !important;
    border: none !important;
  }

  td.mat-mdc-cell {
    padding: 1rem 0.5rem !important;
    border-bottom: none !important;

    //border: none !important;

    &:first-of-type {
      border-radius: 0.5rem 0 0 0.5rem !important;
    }

    &:last-of-type {
      border-radius: 0 0.5rem 0.5rem 0 !important;
    }
  }
}

.semibold-row {
  td.mat-mdc-cell {
    font-size: 18px;
    font-weight: 600;
    color: var(--main-grey-10);
  }
}
