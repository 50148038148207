.nav {
  .nav-subheader {
    display: flex;
    align-items: center;

    height: 54px;
    margin-top: 8px;
    padding-left: 24px;

    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
  }

  .nav-group {
    display: block;

    > .group-title {
      position: relative;

      display: flex;
      align-items: center;

      height: 54px;
      margin-top: 8px;
      padding-left: 24px;

      font-size: 12px;
      font-weight: 600;
      white-space: nowrap;
    }
  }

  .nav-item {
    .nav-link {
      cursor: pointer;
      user-select: none;

      position: relative;

      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      height: 54px;
      padding: 0 24px;

      color: currentcolor;
      text-decoration: none !important;

      > .nav-link-title {
        flex: 1 1 auto;
        white-space: nowrap;
      }

      .nav-link-badge {
        display: flex;
        align-items: center;

        min-width: 20px;
        height: 20px;
        margin-left: 8px;
        padding: 0 7px;

        font-size: 11px;
        font-weight: 600;

        border-radius: 20px;

        transition: opacity 0.2s ease-in-out 0.1s;

        + .collapsable-arrow {
          margin-left: 8px;
        }
      }

      &:hover {
        background-color: var(--md-sys-color-primary-container);

        mat-icon {
          color: var(--md-sys-color-on-primary-container);
        }

        .nav-link-title {
          color: var(--md-sys-color-on-primary-container);
        }
      }

      .mat-ripple-element {
        background-color: rgb(0 0 0 / 4%);
      }

      &.active {
        .nav-link-icon {
          opacity: 1;
        }

        .nav-link-badge {
          color: rgb(0 0 0 / 87%) !important;
          background: #fff !important;
        }
      }

      //.nav-link-icon {
      //  margin-right: 16px;
      //}
      //
      //.nav-link-icon,
      //.collapsable-arrow {
      //  font-size: 16px;
      //  width: 16px;
      //  height: 16px;
      //  min-width: 16px;
      //  min-height: 16px;
      //  line-height: 16px;
      //}
    }

    &.nav-collapsable {
      display: block;

      > .children {
        > .nav-item {
          > .nav-link,
          &.nav-group > .group-title,
          &.nav-group > .group-items > .nav-item > .nav-link {
            padding-left: 13px;
          }

          > .children {
            > .nav-item {
              > .nav-link,
              &.nav-group > .group-title,
              &.nav-group > .group-items > .nav-item > .nav-link {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }

  > .nav-item {
    &.nav-collapsable {
      background: transparent;
      transition: background 300ms ease-in-out;

      &.open {
        //background: rgba(0, 0, 0, 0.12);
      }
    }
  }

  > .nav-group {
    > .group-items {
      > .nav-collapsable {
        background: transparent;
        transition: background 300ms ease-in-out;

        &.open {
          //background: rgba(0, 0, 0, 0.12);
        }
      }
    }
  }

  &.vertical {
    .nav-group {
      .group-title {
        text-transform: uppercase;
      }
    }
  }

  &.horizontal {
    display: flex;
    flex-direction: row;

    .nav-item {
      &.nav-collapsable {
        position: relative;

        .children {
          position: absolute;
          z-index: 999;
          top: 0;
          left: 100%;

          display: none;

          min-width: 200px;

          box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%),
            0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);

          &.open {
            display: block;
          }

          .nav-link {
            padding-left: 24px !important;
          }
        }
      }
    }

    > .nav-item {
      > .nav-link {
        height: 56px;
      }

      &.nav-collapsable {
        position: relative;

        > .nav-link {
          height: 56px;

          .collapsable-arrow {
            display: none;
          }
        }

        > .children {
          top: 100%;
          left: 0;
        }
      }
    }
  }

  //// Material 2 style
  //&.material2,
  //.material2 & {
  //
  //    .nav-subheader {
  //        height: 40px;
  //    }
  //
  //    .nav-group {
  //
  //        > .group-title {
  //            height: 40px;
  //        }
  //    }
  //    .nav-item {
  //
  //        .nav-link {
  //            height: 40px;
  //            padding: 0 12px 0 24px;
  //            border-radius: 0 20px 20px 0;
  //            margin-right: 16px;
  //        }
  //    }
  //}
}

//.wide {
//color: red !important;

.nav-item {
  .nav-link {
    //height: 40px;
    padding: 0 12px 0 24px;
    border-radius: 0 20px 20px 0;

    //margin-right: 16px;

    &:hover {
      background-color: red;
    }

    .nav-link-icon {
      // margin-right: 16px;
      color: #9aa1ad;
    }

    .nav-link-title {
      display: none;
    }

    .nav-link-icon,
    .collapsable-arrow {
      display: grid;
      justify-content: center;

      width: 28px;
      min-width: 28px;
      height: 28px;
      min-height: 28px;

      // --
      // margin-right: 23px;
      margin: 0 auto;

      font-size: 28px;
      line-height: 28px;

      @media screen and (width <= 1599px) {
        width: 20px;
        height: 20px;
        font-size: 20px;
      }
    }
  }
}

.wide {
  .nav-item .nav-link {
    width: 90% !important;
    margin: 0 auto 8px;
    padding: 0 15px;

    .nav-link-icon {
      margin-right: 16px;
    }

    .nav-link-title {
      display: block;

      font-size: 19px;
      font-weight: 500;
      line-height: 22px;
      color: rgb(154 161 173 / 100%);
    }
  }

  .nav-active-blue {
    border-radius: 6px !important;
    transition: border-radius 2s ease-in-out;
  }

  .nav-collapsable {
    display: block;

    > .children {
      > .nav-item {
        > .nav-link,
        &.nav-group > .group-title,
        &.nav-group > .group-items > .nav-item > .nav-link {
          padding-left: 2rem !important;

          //color: red !important;
          //background: green !important;
        }

        > .children {
          > .nav-item {
            > .nav-link,
            &.nav-group > .group-title,
            &.nav-group > .group-items > .nav-item > .nav-link {
              padding-left: 72px !important;
            }
          }
        }
      }
    }
  }
}

.nav-active-blue {
  margin: 0 auto;

  color: var(--md-sys-color-on-primary-container) !important;

  background-color: var(--md-sys-color-primary-container) !important;
  border-radius: 3em !important;

  transition: width 800ms ease;

  .nav-link-icon {
    color: var(--md-sys-color-on-primary-container) !important;
  }

  .nav-link-title {
    color: var(--md-sys-color-on-primary-container) !important;
  }
}

.nav .nav-item .nav-link {
  width: 61px;
  height: 61px;
  margin: 0 auto 10px;
  padding: 0 12px;

  // --
  border-radius: 6px;

  transition: width 800ms ease;

  @media screen and (width <= 1599px) {
    width: 40px;
    height: 40px;
  }
}

.wide {
  // transition: width 800ms ease;
}

//}
