.primary-color {
  color: var(--ion-color-primary);
}

.primary-bg {
  background-color: var(--ion-color-primary);
}

.filled-button {
  color: var(--ion-color-primary);
  background: var(--primary-30);
}
