:root {
  --main-red-100: #fff8f7;
  --main-red-95: #ffedec;
  --main-red-90: #ffdada;
  --main-red-80: #ffb3b5;
  --main-red-70: #ff888e;
  --main-red-60: #ff5165;
  --main-red-50: #dd2243;
  --main-red-40: #be0034;
  --main-red-30: #920025;
  --main-grey-100: #fff;
  --main-grey-95: #fcfcfc;
  --main-grey-90: #f5f5f5;
  --main-grey-80: #ebebeb;
  --main-grey-75: #dbdbdb;
  --main-grey-70: #ccc;
  --main-grey-60: #adadad;
  --main-grey-50: #8f8f8f;
  --main-grey-40: #707070;
  --main-grey-30: #525252;
  --main-grey-20: #333;
  --main-grey-10: #0a0a0a;
  --main-grey-05: #000;
  --main-blue-100: #f7fbff;
  --main-blue-95: #e8f2ff;
  --main-blue-90: #cee5ff;
  --main-blue-80: #95ccff;
  --main-blue-70: #63b1f3;
  --main-blue-60: #207cbb;
  --main-blue-50: #004a75;
  --main-blue-30: #003353;
  --main-blue-20: #001d32;
  --main-warning-100: #fdf29b;
  --main-warning-95: #fdf29b;
  --main-warning-90: #f7e553;
  --main-warning-80: #d9c939;
  --main-warning-70: #bdad1a;
  --main-warning-60: #a09200;
  --main-success-95: #f6ffef;
  --main-success-90: #d8f5ce;
  --main-success-80: #92d787;
  --main-success-70: #77bb6e;
  --main-success-60: #44853f;
  --main-error-100: #fffbff;
  --main-error-96: #ffedea;
  --main-error-90: #ffdad6;
  --main-error-80: #ffb4ab;
  --main-error-70: #ff897d;
  --main-error-60: #ff5449;
  --main-error-50: #de3730;
  --main-error-40: #ba1a1a;
}
