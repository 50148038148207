:root {
  --md-source: #be0034;
  /* primary */
  --md-ref-palette-primary0: #000000;
  --md-ref-palette-primary10: #40000b;
  --md-ref-palette-primary20: #680018;
  --md-ref-palette-primary25: #7c001f;
  --md-ref-palette-primary30: #920026;
  --md-ref-palette-primary35: #a8002d;
  --md-ref-palette-primary40: #be0134;
  --md-ref-palette-primary50: #e22a4a;
  --md-ref-palette-primary60: #ff5165;
  --md-ref-palette-primary70: #ff888e;
  --md-ref-palette-primary80: #ffb3b5;
  --md-ref-palette-primary90: #ffdada;
  --md-ref-palette-primary95: #ffedec;
  --md-ref-palette-primary98: #fff8f7;
  --md-ref-palette-primary99: #fffbff;
  --md-ref-palette-primary100: #ffffff;
  /* secondary */
  --md-ref-palette-secondary0: #000000;
  --md-ref-palette-secondary10: #2c1516;
  --md-ref-palette-secondary20: #44292a;
  --md-ref-palette-secondary25: #503435;
  --md-ref-palette-secondary30: #5d3f40;
  --md-ref-palette-secondary35: #694a4b;
  --md-ref-palette-secondary40: #765657;
  --md-ref-palette-secondary50: #916e6f;
  --md-ref-palette-secondary60: #ad8888;
  --md-ref-palette-secondary70: #c9a2a2;
  --md-ref-palette-secondary80: #e6bdbd;
  --md-ref-palette-secondary90: #ffdada;
  --md-ref-palette-secondary95: #ffedec;
  --md-ref-palette-secondary98: #fff8f7;
  --md-ref-palette-secondary99: #fffbff;
  --md-ref-palette-secondary100: #ffffff;
  /* tertiary */
  --md-ref-palette-tertiary0: #000000;
  --md-ref-palette-tertiary10: #001d33;
  --md-ref-palette-tertiary20: #003353;
  --md-ref-palette-tertiary25: #003e64;
  --md-ref-palette-tertiary30: #004a76;
  --md-ref-palette-tertiary35: #005688;
  --md-ref-palette-tertiary40: #00639b;
  --md-ref-palette-tertiary50: #227cbb;
  --md-ref-palette-tertiary60: #4696d7;
  --md-ref-palette-tertiary70: #64b1f3;
  --md-ref-palette-tertiary80: #96cbff;
  --md-ref-palette-tertiary90: #cee5ff;
  --md-ref-palette-tertiary95: #e8f2ff;
  --md-ref-palette-tertiary98: #f7f9ff;
  --md-ref-palette-tertiary99: #fcfcff;
  --md-ref-palette-tertiary100: #ffffff;
  /* neutral */
  --md-ref-palette-neutral0: #000000;
  --md-ref-palette-neutral10: #201a1a;
  --md-ref-palette-neutral20: #362f2f;
  --md-ref-palette-neutral25: #413a3a;
  --md-ref-palette-neutral30: #4d4545;
  --md-ref-palette-neutral35: #595050;
  --md-ref-palette-neutral40: #655c5c;
  --md-ref-palette-neutral50: #7e7575;
  --md-ref-palette-neutral60: #998e8e;
  --md-ref-palette-neutral70: #b4a9a8;
  --md-ref-palette-neutral80: #d0c4c3;
  --md-ref-palette-neutral90: #ece0df;
  --md-ref-palette-neutral95: #fbeeed;
  --md-ref-palette-neutral98: #fff8f7;
  --md-ref-palette-neutral99: #fffbff;
  --md-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  --md-ref-palette-neutral-variant0: #000000;
  --md-ref-palette-neutral-variant10: #251819;
  --md-ref-palette-neutral-variant20: #3b2d2d;
  --md-ref-palette-neutral-variant25: #463838;
  --md-ref-palette-neutral-variant30: #524343;
  --md-ref-palette-neutral-variant35: #5f4f4f;
  --md-ref-palette-neutral-variant40: #6b5a5a;
  --md-ref-palette-neutral-variant50: #857373;
  --md-ref-palette-neutral-variant60: #9f8c8c;
  --md-ref-palette-neutral-variant70: #bba6a6;
  --md-ref-palette-neutral-variant80: #d7c1c1;
  --md-ref-palette-neutral-variant90: #f4dddd;
  --md-ref-palette-neutral-variant95: #ffedec;
  --md-ref-palette-neutral-variant98: #fff8f7;
  --md-ref-palette-neutral-variant99: #fffbff;
  --md-ref-palette-neutral-variant100: #ffffff;
  /* error */
  --md-ref-palette-error0: #000000;
  --md-ref-palette-error10: #410002;
  --md-ref-palette-error20: #690005;
  --md-ref-palette-error25: #7e0007;
  --md-ref-palette-error30: #93000a;
  --md-ref-palette-error35: #a80710;
  --md-ref-palette-error40: #ba1a1a;
  --md-ref-palette-error50: #de3730;
  --md-ref-palette-error60: #ff5449;
  --md-ref-palette-error70: #ff897d;
  --md-ref-palette-error80: #ffb4ab;
  --md-ref-palette-error90: #ffdad6;
  --md-ref-palette-error95: #ffedea;
  --md-ref-palette-error98: #fff8f7;
  --md-ref-palette-error99: #fffbff;
  --md-ref-palette-error100: #ffffff;
  /* light */
  --md-sys-color-primary-light: #be0134;
  --md-sys-color-on-primary-light: #ffffff;
  --md-sys-color-primary-container-light: #ffdada;
  --md-sys-color-on-primary-container-light: #40000b;
  --md-sys-color-secondary-light: #765657;
  --md-sys-color-on-secondary-light: #ffffff;
  --md-sys-color-secondary-container-light: #ffdada;
  --md-sys-color-on-secondary-container-light: #2c1516;
  --md-sys-color-tertiary-light: #00639b;
  --md-sys-color-on-tertiary-light: #ffffff;
  --md-sys-color-tertiary-container-light: #cee5ff;
  --md-sys-color-on-tertiary-container-light: #001d33;
  --md-sys-color-error-light: #ba1a1a;
  --md-sys-color-error-container-light: #ffdad6;
  --md-sys-color-on-error-light: #ffffff;
  --md-sys-color-on-error-container-light: #410002;
  --md-sys-color-background-light: #fffbff;
  --md-sys-color-on-background-light: #201a1a;
  --md-sys-color-surface-light: #fffbff;
  --md-sys-color-on-surface-light: #201a1a;
  --md-sys-color-surface-variant-light: #f4dddd;
  --md-sys-color-on-surface-variant-light: #524343;
  --md-sys-color-outline-light: #857373;
  --md-sys-color-inverse-on-surface-light: #fbeeed;
  --md-sys-color-inverse-surface-light: #362f2f;
  --md-sys-color-inverse-primary-light: #ffb3b5;
  --md-sys-color-shadow-light: #000000;
  --md-sys-color-surface-tint-light: #be0134;
  --md-sys-color-outline-variant-light: #d7c1c1;
  --md-sys-color-scrim-light: #000000;
  /* dark */
  --md-sys-color-primary-dark: #ffb3b5;
  --md-sys-color-on-primary-dark: #680018;
  --md-sys-color-primary-container-dark: #920026;
  --md-sys-color-on-primary-container-dark: #ffdada;
  --md-sys-color-secondary-dark: #e6bdbd;
  --md-sys-color-on-secondary-dark: #44292a;
  --md-sys-color-secondary-container-dark: #5d3f40;
  --md-sys-color-on-secondary-container-dark: #ffdada;
  --md-sys-color-tertiary-dark: #96cbff;
  --md-sys-color-on-tertiary-dark: #003353;
  --md-sys-color-tertiary-container-dark: #004a76;
  --md-sys-color-on-tertiary-container-dark: #cee5ff;
  --md-sys-color-error-dark: #ffb4ab;
  --md-sys-color-error-container-dark: #93000a;
  --md-sys-color-on-error-dark: #690005;
  --md-sys-color-on-error-container-dark: #ffdad6;
  --md-sys-color-background-dark: #201a1a;
  --md-sys-color-on-background-dark: #ece0df;
  --md-sys-color-surface-dark: #201a1a;
  --md-sys-color-on-surface-dark: #ece0df;
  --md-sys-color-surface-variant-dark: #524343;
  --md-sys-color-on-surface-variant-dark: #d7c1c1;
  --md-sys-color-outline-dark: #9f8c8c;
  --md-sys-color-inverse-on-surface-dark: #201a1a;
  --md-sys-color-inverse-surface-dark: #ece0df;
  --md-sys-color-inverse-primary-dark: #be0134;
  --md-sys-color-shadow-dark: #000000;
  --md-sys-color-surface-tint-dark: #ffb3b5;
  --md-sys-color-outline-variant-dark: #524343;
  --md-sys-color-scrim-dark: #000000;
  /* display - large */
  --md-sys-typescale-display-large-font-family-name: Roboto;
  --md-sys-typescale-display-large-font-family-style: Regular;
  --md-sys-typescale-display-large-font-weight: 400px;
  --md-sys-typescale-display-large-font-size: 57px;
  --md-sys-typescale-display-large-line-height: 64px;
  --md-sys-typescale-display-large-letter-spacing: -0.25px;
  /* display - medium */
  --md-sys-typescale-display-medium-font-family-name: Roboto;
  --md-sys-typescale-display-medium-font-family-style: Regular;
  --md-sys-typescale-display-medium-font-weight: 400px;
  --md-sys-typescale-display-medium-font-size: 45px;
  --md-sys-typescale-display-medium-line-height: 52px;
  --md-sys-typescale-display-medium-letter-spacing: 0px;
  /* display - small */
  --md-sys-typescale-display-small-font-family-name: Roboto;
  --md-sys-typescale-display-small-font-family-style: Regular;
  --md-sys-typescale-display-small-font-weight: 400px;
  --md-sys-typescale-display-small-font-size: 36px;
  --md-sys-typescale-display-small-line-height: 44px;
  --md-sys-typescale-display-small-letter-spacing: 0px;
  /* headline - large */
  --md-sys-typescale-headline-large-font-family-name: Roboto;
  --md-sys-typescale-headline-large-font-family-style: Regular;
  --md-sys-typescale-headline-large-font-weight: 400px;
  --md-sys-typescale-headline-large-font-size: 32px;
  --md-sys-typescale-headline-large-line-height: 40px;
  --md-sys-typescale-headline-large-letter-spacing: 0px;
  /* headline - medium */
  --md-sys-typescale-headline-medium-font-family-name: Roboto;
  --md-sys-typescale-headline-medium-font-family-style: Regular;
  --md-sys-typescale-headline-medium-font-weight: 400px;
  --md-sys-typescale-headline-medium-font-size: 28px;
  --md-sys-typescale-headline-medium-line-height: 36px;
  --md-sys-typescale-headline-medium-letter-spacing: 0px;
  /* headline - small */
  --md-sys-typescale-headline-small-font-family-name: Roboto;
  --md-sys-typescale-headline-small-font-family-style: Regular;
  --md-sys-typescale-headline-small-font-weight: 400px;
  --md-sys-typescale-headline-small-font-size: 24px;
  --md-sys-typescale-headline-small-line-height: 32px;
  --md-sys-typescale-headline-small-letter-spacing: 0px;
  /* body - large */
  --md-sys-typescale-body-large-font-family-name: Roboto;
  --md-sys-typescale-body-large-font-family-style: Regular;
  --md-sys-typescale-body-large-font-weight: 400px;
  --md-sys-typescale-body-large-font-size: 16px;
  --md-sys-typescale-body-large-line-height: 24px;
  --md-sys-typescale-body-large-letter-spacing: 0.50px;
  /* body - medium */
  --md-sys-typescale-body-medium-font-family-name: Roboto;
  --md-sys-typescale-body-medium-font-family-style: Regular;
  --md-sys-typescale-body-medium-font-weight: 400px;
  --md-sys-typescale-body-medium-font-size: 14px;
  --md-sys-typescale-body-medium-line-height: 20px;
  --md-sys-typescale-body-medium-letter-spacing: 0.25px;
  /* body - small */
  --md-sys-typescale-body-small-font-family-name: Roboto;
  --md-sys-typescale-body-small-font-family-style: Regular;
  --md-sys-typescale-body-small-font-weight: 400px;
  --md-sys-typescale-body-small-font-size: 12px;
  --md-sys-typescale-body-small-line-height: 16px;
  --md-sys-typescale-body-small-letter-spacing: 0.40px;
  /* label - large */
  --md-sys-typescale-label-large-font-family-name: Roboto;
  --md-sys-typescale-label-large-font-family-style: Medium;
  --md-sys-typescale-label-large-font-weight: 500px;
  --md-sys-typescale-label-large-font-size: 14px;
  --md-sys-typescale-label-large-line-height: 20px;
  --md-sys-typescale-label-large-letter-spacing: 0.10px;
  /* label - medium */
  --md-sys-typescale-label-medium-font-family-name: Roboto;
  --md-sys-typescale-label-medium-font-family-style: Medium;
  --md-sys-typescale-label-medium-font-weight: 500px;
  --md-sys-typescale-label-medium-font-size: 12px;
  --md-sys-typescale-label-medium-line-height: 16px;
  --md-sys-typescale-label-medium-letter-spacing: 0.50px;
  /* label - small */
  --md-sys-typescale-label-small-font-family-name: Roboto;
  --md-sys-typescale-label-small-font-family-style: Medium;
  --md-sys-typescale-label-small-font-weight: 500px;
  --md-sys-typescale-label-small-font-size: 11px;
  --md-sys-typescale-label-small-line-height: 16px;
  --md-sys-typescale-label-small-letter-spacing: 0.50px;
  /* title - large */
  --md-sys-typescale-title-large-font-family-name: Roboto;
  --md-sys-typescale-title-large-font-family-style: Regular;
  --md-sys-typescale-title-large-font-weight: 400px;
  --md-sys-typescale-title-large-font-size: 22px;
  --md-sys-typescale-title-large-line-height: 28px;
  --md-sys-typescale-title-large-letter-spacing: 0px;
  /* title - medium */
  --md-sys-typescale-title-medium-font-family-name: Roboto;
  --md-sys-typescale-title-medium-font-family-style: Medium;
  --md-sys-typescale-title-medium-font-weight: 500px;
  --md-sys-typescale-title-medium-font-size: 16px;
  --md-sys-typescale-title-medium-line-height: 24px;
  --md-sys-typescale-title-medium-letter-spacing: 0.15px;
  /* title - small */
  --md-sys-typescale-title-small-font-family-name: Roboto;
  --md-sys-typescale-title-small-font-family-style: Medium;
  --md-sys-typescale-title-small-font-weight: 500px;
  --md-sys-typescale-title-small-font-size: 14px;
  --md-sys-typescale-title-small-line-height: 20px;
  --md-sys-typescale-title-small-letter-spacing: 0.10px;
}
