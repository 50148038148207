.mat-mdc-button {
  &.m3 {
    // --
    display: flex;
    align-items: center;

    height: 40px !important;
    padding-inline: 1.5rem !important;

    border-radius: 2rem !important;

    span {
      // --
      display: flex !important;
      align-items: center !important;

      height: 100% !important;

      font-family: Roboto, sans-serif !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 20px !important;
    }

    mat-icon {
      width: 18px !important;
      height: 18px !important;
      margin-right: 0.5rem;

      font-size: 18px !important;
      line-height: 18px !important;
    }

    &.icon {
      mat-icon {
        margin: 0 !important;
      }
    }

    &.elevated {
      color: var(--md-sys-color-primary);
      background: var(--md-sys-color-secondary-container);
      box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
        0 1px 3px 0 rgb(0 0 0 / 12%);
    }

    &.filled {
      color: var(--md-sys-color-on-primary);
      background: var(--md-sys-color-primary);
    }

    &.tonal {
      color: var(--md-sys-color-on-secondary-container);
      background: var(--md-sys-color-secondary-container);
    }

    &.outlined {
      color: var(--md-sys-color-primary);
      background: var(--md-sys-color-surface);
      border: 1px solid var(--md-sys-color-outline);
    }

    &.text {
      color: var(--md-sys-color-primary);
      background: transparent;
    }
  }

  &.mat-mdc-button[disabled] {
    opacity: 0.6 !important;
  }
}

.mat-mdc-icon-button {
  &.m3 {
    display: grid;
    place-items: center !important;

    width: 40px !important;
    height: 40px !important;
    padding: 0 !important;

    border-radius: 50% !important;

    mat-icon {
      color: var(--md-sys-color-primary);

      &.material-icons-outlined {
        color: var(--md-sys-color-on-surface-variant);
      }
    }

    &.filled {
      background-color: var(--md-sys-color-primary);

      mat-icon {
        color: var(--md-sys-color-on-primary);
      }
    }

    &.tonal {
      background-color: var(--md-sys-color-secondary-container);

      mat-icon {
        color: var(--md-sys-color-on-secondary-container);
      }
    }

    &.outlined {
      // background-color: var(--md-sys-color-inverse-surface);
      border: 1px solid var(--md-sys-color-outline);

      mat-icon {
        color: var(--md-sys-color-on-surface-variant);
      }
    }
  }

  &.mat-mdc-icon-button[disabled] {
    opacity: 0.6 !important;
  }
}

.mat-mdc-card {
  &.m3 {
    // mat-card-body has the correct padding
    padding: 0 !important;
    border-radius: 12px !important;

    .mat-mdc-card-header {
      display: flex;
      align-items: center;

      //height: 64px !important;
      // // It's height will be 64px with <span class="title-large">
      padding: 1rem 1rem 0 !important;

      //padding-inline: 1rem !important;

      img {
        border-radius: 12px !important;
      }
    }

    .mat-mdc-card-content {
      padding: 1rem !important;
    }

    &.elevated {
      background: var(--md-ref-palette-neutral95);
      box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%);

      // Dark mode media
      //@media (prefers-color-scheme: dark) {
      //  background: var(--md-ref-palette-neutral10);
      //}
    }

    &.filled {
      background: var(--md-ref-palette-neutral90);
      box-shadow: none !important;

      // Dark mode media
      //@media (prefers-color-scheme: dark) {
      //  background: var(--md-ref-palette-neutral20);
      //}
    }

    &.outlined {
      background: var(--md-ref-palette-neutral98);
      border: 1px solid var(--md-ref-palette-neutral50);
      box-shadow: none !important;

      // Dark mode media
      //@media (prefers-color-scheme: dark) {
      //  background: var(--md-ref-palette-neutral10);
      //}
    }
  }
}

.mat-divider {
  border: 1px solid var(--md-ref-palette-neutral-variant80);
}

.mat-mdc-chip-listbox {
  &.m3 {
    .mat-mdc-chip {
      height: 2rem !important;
      background: transparent;
      border: 1px solid var(--md-sys-color-outline);
      border-radius: 0.5rem !important;
    }

    .mat-mdc-chip-action-label {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .mat-mdc-icon {
        width: 18px !important;
        height: 18px !important;
        font-size: 18px !important;
        line-height: 18px !important;
      }
    }

    .mat-mdc-chip-selected {
      background-color: var(--md-sys-color-secondary-container);

      * {
        color: var(--md-sys-color-on-secondary-container) !important;
      }
    }

    .mat-mdc-standard-chip:not(.mdc-evolution-chip--selected)
      .mdc-evolution-chip__action--primary::before {
      border: none !important;
    }
  }
}

:host(.menu-type-overlay) .menu-inner {
  border-radius: 1rem 0 0 1rem !important;
}

ul {
  &.m3 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    li {
      position: relative;

      display: flex;
      gap: 1rem;
      align-items: center;

      width: 100%;
      height: 100%;
      min-height: 56px;
      padding: 0.5rem 1.5rem 0.5rem 1rem;
    }
  }
}
