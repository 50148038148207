body:not(.is-mobile) {
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: rgb(0 0 0 / 0%);
  }

  ::-webkit-scrollbar:hover {
    background-color: rgb(0 0 0 / 12%);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 12px;
    box-shadow: inset 0 0 0 12px rgb(0 0 0 / 37%);
  }

  ::-webkit-scrollbar-thumb:active {
    border-radius: 12px;
    box-shadow: inset 0 0 0 12px rgb(0 0 0 / 54%);
  }
}
